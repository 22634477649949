import type {MetaHTMLAttributes, LinkHTMLAttributes} from 'react';

/**
 * @description detects if the device is iOS based on the provided user agent
 * @param {string|undefined} userAgent user agent, optional parameter
 * @return {boolean} - The result of the validation: is iOS or not
 */
export const isIOS = (userAgent?: string) => (userAgent ? /iPad|iPhone/.test(userAgent) : false);

const metaTagsIOS = [
  {
    name: 'viewport',
    content: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0',
    id: 'home-page-ios-viewport',
  },
];

const metaTags = [
  {
    name: 'description',
    content:
      'The art marketplace. Shop for wall art, original paintings, sculptures, limited edition prints and photography directly from independent artists.',
    /**
     * @description key is used to identify the metadata in the page head to avoid duplication
     * @link https://nextjs.org/docs/pages/api-reference/components/head#avoid-duplicated-tags
     */
    id: 'home-page-description',
  },
  {
    name: 'robots',
    content: 'index,follow',
    id: 'home-page-robots',
  },
  {
    property: 'og:site_name',
    content: 'Artfinder',
    id: 'home-page-og-site-name',
  },
  {
    property: 'og:title',
    content: 'Shop Original Art, Wall Art & Art Prints | Artfinder',
    id: 'home-page-og-title',
  },
  {
    property: 'og:description',
    content:
      'The art marketplace. Shop for wall art, original paintings, sculptures, limited edition prints and photography directly from independent artists.',

    id: 'home-page-og-description',
  },
  {
    property: 'og:image',
    content:
      'https://d3rf6j5nx5r04a.cloudfront.net/xmewmbFMA1h9nOHCcoO_HcUCiEU=/1200x630/content/f/3/c628317dd64942fd8f0b6b49c5d8f5f5.jpg',

    id: 'home-page-og-image',
  },
];

/**
 * @description generate home page's metadata
 * @param {{userAgent: string}} params - user agent optional parameter
 * @return {{metaTags: Record<string, unknown>[], links: Record<string, unknown>[], title: string, }} table of of metadata attributes
 */
export const getMetadata = ({
  userAgent,
}: {
  userAgent?: string;
}): {
  title: string;
  metaTags: (MetaHTMLAttributes<HTMLMetaElement> & {id: string})[];
  links: (LinkHTMLAttributes<HTMLLinkElement> & {id: string})[];
  regionDescription: Record<
    | 'uspBottom'
    | 'joinMailingList'
    | 'shopByCategory'
    | 'shopByPrice'
    | 'popularArtists'
    | 'discoverArtists'
    | 'editorsPicks'
    | 'customerReviews',
    string
  >;
} => ({
  title: 'Shop Original Art, Wall Art & Art Prints | Artfinder',
  metaTags: isIOS(userAgent) ? metaTags.concat(metaTagsIOS) : metaTags,
  links: [
    {
      id: 'home-page-favicon-link',
      rel: 'icon',
      href: '/favicon.ico',
    },
    {
      id: 'home-page-canonical-link',
      rel: 'canonical',
      href: 'https://www.artfinder.com/',
    },
    {
      id: 'home-page-alternate-link',
      rel: 'alternate',
      href: 'https://www.artfinder.com/',
      hrefLang: 'en',
    },
  ],
  regionDescription: {
    uspBottom:
      'This page section is the customer support area, consisting of three cards with information on customer care, commissioning an artist, and free returns. Each card has a link to more information.',
    shopByCategory:
      'This page section is the shop by category area, consisting of a header with a title and a view all link, and a list of categories with links to each category page.',
    joinMailingList:
      'Sign up below to join Artfinder’s email newsletter, by filling in the field below with your email address then press enter key or click the subscribe button.',
    shopByPrice:
      'This is the shop by price section, with links to shop art by price range. How to use this section: click on the button link to view art within the price range.',
    popularArtists:
      'This is the popular artists content, represented by list of 4 artists with links to individual artist pages. How to use this section: in the list click the artist card to view the individual artist page.',
    discoverArtists:
      'This is the discover artists content, a variety of artists around the world: Painters. Sculptors. Photographers. And then some. Represented by list of 8 artists with links to individual artist pages.',

    editorsPicks:
      'This section contains collections, made by our curation team, of carefully selected artworks around various topics and themes. Represented by list of 4 best recommended seasonal picks with links to the selection page.',

    customerReviews:
      'This section contains customer reviews by TrustPilot, represented by the Artfinder rating and a list of reviews, each is linked to the TrustPilot review page.',
  },
});
