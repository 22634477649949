import StyledActionButton from '@/molecules/Buttons/Action/styles';
import Rating from '@/molecules/Rating/Rating';
import colors from '@/theme/constants/colors';
import {sizePx} from '@/theme/helpers/size';
import typography from '@/theme/typography';
import elevation from '@/theme/elevation';
import styled from 'styled-components';
import focus from '@/theme/focus';

const localZindex = {
  followButton: 20,
  link: 10,
};

export const StyledArtistCard = styled.div`
  position: relative;
  background: ${colors.white};
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  transition: box-shadow 0.05s ease-in;
  border-radius: 4px;
  width: ${sizePx(150)};
  padding: 16px;

  > a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: inherit;
    ${focus.brand(elevation.value('Highlight'))}
    z-index: ${localZindex.link};
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${elevation.Hover}
    }
  }

  &:active {
    ${elevation.Highlight}
  }
`;

export const StyledImgGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'img1 img1' 'img2 img3';
  gap: 2px;
  border-radius: ${sizePx(1)};
  overflow: hidden;
  margin-bottom: 16px;

  figure {
    width: 100%;
    height: 100%;
  }

  figure:nth-child(1) {
    grid-area: img1;
  }

  figure:nth-child(2) {
    grid-area: img2;
  }

  figure:nth-child(3) {
    grid-area: img3;
  }
`;

export const StyledArtistInfo = styled.div`
  display: grid;
  grid-template-columns: 64px 1fr;
  grid-template-areas: 'avatar name' 'avatar country' 'avatar stars';
  column-gap: 12px;

  figure {
    border-radius: 50%;
    grid-area: avatar;
  }

  ${StyledActionButton} {
    z-index: ${localZindex.followButton};
  }
`;

export const StyledArtistName = styled.p`
  ${typography.Subtitle1}
  line-height: initial;
  grid-area: name;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledArtistCountry = styled.p`
  ${typography.Body1}
  grid-area: country;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledRating = styled(Rating)`
  grid-area: stars;
`;
