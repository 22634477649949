import styled, {css, keyframes} from 'styled-components';
import colors from '@/theme/constants/colors';
import {sizePx} from '@/theme/helpers/size';
import elevation from '@/theme/elevation';

const skeletonBgColor = colors.grey50;

const skeletonAnimation = keyframes`
  35% {
    opacity: 0.5;
  }
  75% {
    opacity: 1;
  }
`;

const skeletonBg = css`
  background: ${skeletonBgColor};
  animation: 2.5s ${skeletonAnimation} ease-in-out infinite;
`;

export const StyledContentCardSkeleton = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: ${sizePx(2)};
  background: ${colors.white};
  width: ${sizePx(90)};
  ${elevation.Default}
  min-width: 180px;
`;

export const StyledContentCardImageSkeleton = styled.div`
  ${skeletonBg}
  border-radius: ${sizePx(2)} ${sizePx(2)} 0 0;
  aspect-ratio: 1 / 1;
`;

export const StyledContentCardTitleSkeleton = styled.div`
  padding: ${sizePx(4)} ${sizePx(8)};
  & > div {
    ${skeletonBg};
    height: 26px;
    width: 75%;
    border-radius: ${sizePx(1)};
  }
`;
