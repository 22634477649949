import React, {ReactElement, forwardRef} from 'react';
import {StyledTextfield, StyledContainer} from './styles';
import Icon from '../../atoms/Icon/Icon';
import Props from './typings';
import colors from '../../theme/constants/colors';

const Textfield = forwardRef<HTMLInputElement, Props>(
  (
    {
      placeholder,
      width,
      disabled = false,
      fluidWidth,
      icon,
      error = false,
      value,
      callback,
      ...restInputProps
    },
    ref,
  ): ReactElement => {
    const iconColor = error
      ? colors.red100
      : disabled
      ? colors.grey25
      : value
      ? colors.darkBlue100
      : colors.grey100;

    return (
      <StyledContainer $width={width} $fluidWidth={fluidWidth}>
        <StyledTextfield
          placeholder={placeholder}
          onChange={callback ? (e) => callback(e.target.value) : undefined}
          $error={error}
          aria-disabled={disabled}
          value={value}
          ref={ref}
          {...restInputProps}
        />

        {icon && <Icon type={icon} color={iconColor} />}
      </StyledContainer>
    );
  },
);

export default Textfield;
