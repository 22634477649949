'use client';

import React, {forwardRef} from 'react';

import type {CustomerSupportUspProps as Props} from './typings';
import Icon from '@/atoms/Icon/Icon';
import {StyledCustomerSupportUsp, StyledIconContainer} from './styles';

export {StyledCardsList} from './styles';

const CustomerSupportUsp = forwardRef<HTMLLIElement, Props>(
  ({icon, title, subTitle, linkHref, linkText, ...restLiProps}, ref): JSX.Element => (
    <StyledCustomerSupportUsp ref={ref} {...restLiProps}>
      <StyledIconContainer>
        <Icon type={icon} />
      </StyledIconContainer>

      <h3>{title}</h3>

      <p>{subTitle}</p>

      <p>{linkText}</p>

      <a aria-label={`Read more about ${title}`} href={linkHref}></a>
    </StyledCustomerSupportUsp>
  ),
);

CustomerSupportUsp.displayName = 'CustomerSupportUsp';

export default CustomerSupportUsp;
