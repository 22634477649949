import styled from 'styled-components';

import breakpoints from '@/theme/constants/breakpoints';
import pxToRem from '@/theme/helpers/pxToRem';
import colors from '@/theme/constants/colors';
import typography from '@/theme/typography';

export const StyledJoinMailingListForm = styled.form`
  & ~ div[role='dialog'] {
    & > div:not(div[aria-label='success message']) {
      top: ${pxToRem(8)};
      right: ${pxToRem(8)};
    }

    @media only screen and ${breakpoints.device.s} {
      min-width: ${pxToRem(350)};
    }
  }
`;

export const StyledJoinMailingListFormContainer = styled.div`
  display: grid;
  grid-template-columns: ${pxToRem(191)} ${pxToRem(91)};
  gap: ${pxToRem(8)};
  & > button,
  & input {
    height: ${pxToRem(44)};
  }
  & > button {
    font-weight: 700;
  }
  & input {
    border-radius: ${pxToRem(4)};
    padding-left: ${pxToRem(12)};
  }
`;

export const StyledErrorsList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: ${pxToRem(4)};
  justify-content: center;
  align-items: start;
  list-style: none;
  padding: 0;
  margin: ${pxToRem(4)} 0 0 0;
  & > li {
    color: ${colors.red100};
    ${typography.Body3}
  }
`;

export const StyledSuccessMessageContainer = styled.div`
  width: ${pxToRem(350)};
  padding: ${pxToRem(32)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${pxToRem(17)};
  background-color: ${colors.grey50};
  border-radius: ${pxToRem(8)};

  & > p {
    color: ${colors.darkBlue100};
    text-align: center;
    max-width: ${pxToRem(285)};
    ${typography.Body1}
  }

  & > p:first-of-type {
    ${typography.Art}
  }
`;
