import {css, keyframes, styled} from 'styled-components';
import colors from '@/theme/constants/colors';
import {sizePx} from '@/theme/helpers/size';
import breakpoints from '@/theme/constants/breakpoints';

const contentBgColor = colors.grey50;

const contentAnimation = keyframes`
  35% {
    opacity: 0.5;
  }
  75% {
    opacity: 1;
  }
`;

const contentBg = css`
  background: ${contentBgColor};
  animation: 2.5s ${contentAnimation} ease-in-out infinite;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sizePx(16)};
  padding: ${sizePx(16)} 0;

  @media only screen and ${breakpoints.device.s} {
    flex-direction: row;
    gap: ${sizePx(12)};
    padding: ${sizePx(14)} 0;

    > * {
      flex-basis: 50%;
    }
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${sizePx(6)};
`;

export const StyledHeader = styled.div`
  height: ${sizePx(16)};
  width: 80%;
  ${contentBg}
`;

export const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sizePx(6)};

  > div {
    height: ${sizePx(9)};
    width: 100%;
    animation-delay: 1s;
    ${contentBg}
  }

  > div:last-child {
    width: 40%;
  }
`;

export const StyledButton = styled.div`
  height: ${sizePx(22)};
  width: ${sizePx(70)};
  border-radius: ${sizePx(2)};
  animation-delay: 1.5s;
  margin-top: ${sizePx(6)};
  ${contentBg}
`;

export const StyledImg = styled.div`
  aspect-ratio: 16 / 9;
  border-radius: ${sizePx(8)};
  ${contentBg}
`;
