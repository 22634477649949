import type Props from './typings';

import {
  StyledArtistCardSkeleton,
  StyledStyledImageGridSkeleton,
  StyledArtistInfoSkeleton,
  StyledAvatarSkeleton,
  StyledSummarySkeleton,
  StyledFollowButtonSkeleton,
  StyledRatingSkeleton,
} from './style';

const ArtistCardSkeleton = ({followBtn}: Props): JSX.Element => (
  <StyledArtistCardSkeleton>
    <StyledStyledImageGridSkeleton>
      <div />
      <div />
      <div />
    </StyledStyledImageGridSkeleton>
    <StyledArtistInfoSkeleton>
      <StyledAvatarSkeleton>
        <div />
      </StyledAvatarSkeleton>
      <StyledSummarySkeleton>
        <div />
        <div />
        {followBtn ? <StyledFollowButtonSkeleton /> : <StyledRatingSkeleton />}
      </StyledSummarySkeleton>
    </StyledArtistInfoSkeleton>
  </StyledArtistCardSkeleton>
);

export default ArtistCardSkeleton;
