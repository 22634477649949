import StyledIcon from '@/atoms/Icon/styles';
import colors from '@/theme/constants/colors';
import typography from '@/theme/typography';
import styled from 'styled-components';
import {StyledSmallUspProps} from './typings';
import breakpoints from '@/theme/constants/breakpoints';
import pxToRem from '@/theme/helpers/pxToRem';

export const StyledSmallUsp = styled.div<StyledSmallUspProps>`
  max-width: 276px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${StyledIcon} {
    width: 34px;
    height: 34px;
    font-size: ${pxToRem(14)};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    ${(props) => props.$background && `background: ${props.$background};`}
  }

  p {
    margin-top: 8px;
    ${typography.Art}
    color: ${colors.darkBlue100};
    text-align: center;
    cursor: default;
  }

  @media only screen and ${breakpoints.device.l} {
    ${StyledIcon} {
      width: 48px;
      height: 48px;
      font-size: ${pxToRem(19)};
    }
  }
`;
