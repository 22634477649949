import Icon from '@/atoms/Icon/Icon';
import {StyledSmallUsp} from './styles';
import colors from '@/theme/constants/colors';

const SmallUsp = ({icon, background, children}) => {
  return (
    <StyledSmallUsp $background={background}>
      <Icon type={icon} color={colors.darkBlue100} />

      <p>{children}</p>
    </StyledSmallUsp>
  );
};

export default SmallUsp;
