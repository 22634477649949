import {StyledOutlinedLink} from './styles';
import Props from './typings';

const OutlinedLink = ({title, className, href, target, maxWidth, children}: Props) => {
  return (
    <StyledOutlinedLink
      className={className}
      href={href}
      target={target}
      role="button"
      aria-label={title ? title : children}
      $maxWidth={maxWidth}
    >
      {children}
    </StyledOutlinedLink>
  );
};

export default OutlinedLink;
