import {
  StyledArtistCard,
  StyledImgGrid,
  StyledArtistInfo,
  StyledArtistName,
  StyledArtistCountry,
  StyledRating,
} from './styles';
import {Props} from './typings';
import RibbonLabel from '@/atoms/RibbonLabel/RibbonLabel';
import FollowButton from '@/molecules/Buttons/Follow/FollowButton';
import {
  productCardImageSrcSetDef,
  PRODUCT_CARD_SIZES,
  FALLBACK_WIDTH,
} from '../../../../services/image-loader';
import Image from '@/atoms/Image/Image';
import Link from 'next/link';

const ArtistCard = ({artist, followBtn}: Props) => {
  return (
    <StyledArtistCard>
      {!!artist.sale_discount_amount && (
        <RibbonLabel>{`Sale - save ${artist.sale_discount_amount}%`}</RibbonLabel>
      )}
      <Link href={`/artist/${artist.slug}`} aria-label={artist.name} />

      <StyledImgGrid>
        {artist.images.slice(0, 3).map((image, i) => (
          <Image
            alt={`Artwork by ${artist.name}`}
            path={image.path}
            srcSetDef={productCardImageSrcSetDef(image.path)}
            sizes={PRODUCT_CARD_SIZES}
            fallbackWidth={FALLBACK_WIDTH}
            width={i === 0 ? 268 : 133}
            height={134}
            key={i}
            responsive
            retina
          />
        ))}
      </StyledImgGrid>

      <StyledArtistInfo>
        <Image path={artist.avatar_url} alt={artist.name} width={64} height={64} retina />

        <StyledArtistName>{artist.name}</StyledArtistName>

        <StyledArtistCountry>{artist.country}</StyledArtistCountry>

        {followBtn ? (
          <FollowButton id={artist.id} slug={artist.slug} name={artist.name} />
        ) : (
          !!artist.seller_rating && <StyledRating value={artist.seller_rating} size={16} />
        )}
      </StyledArtistInfo>
    </StyledArtistCard>
  );
};

export default ArtistCard;
