import SmallUspProps from '@/molecules/USPs/small/typings';
import {CurrencySymbol} from '@/redux/reducers/typings';
import colors from '@/theme/constants/colors';
import {CustomerSupportUspContentProps} from './typings';

export const customerSupportUspContent: CustomerSupportUspContentProps = {
  title: 'Our unique selling points',
  cards: [
    {
      icon: 'customer-support',
      title: 'Exceptional customer care',
      subTitle: 'Our dedicated support team is always on hand to help.',
      linkHref: '/customer-care/',
      linkText: 'More info',
    },
    {
      icon: 'image',
      title: 'Commission an artist',
      subTitle:
        "Can't quite find the perfect piece? Or looking for a family or pet portrait? Commission an artist and make your dream a reality.",
      linkHref: '/commissions/',
      linkText: 'More info',
    },
    {
      icon: 'truck',
      title: 'Free returns',
      subTitle:
        "If you're not completely in love with your artwork, you can return it for free. No questions asked.",
      linkHref: '/free-returns/',
      linkText: 'More info',
    },
  ],
};

export const shopByCategoryContent = {
  title: 'Shop by category',
  links: {
    viewAllLinkHref: '/art/',
    categories: [
      {
        id: '1',
        linkText: 'Paintings',
        linkHref: '/art/product_category-painting/',
      },
      {
        id: '2',
        linkText: 'Printmaking',
        linkHref: '/art/product_category-printmaking/',
      },
      {
        id: '3',
        linkText: 'Photography',
        linkHref: '/art/product_category-photography/',
      },
      {
        id: '4',
        linkText: 'Sculptures',
        linkHref: '/art/product_category-sculpture/',
      },
      {
        id: '5',
        linkText: 'Abstract art',
        linkHref: '/art/subject-abstract-conceptual/',
      },
      {
        id: '6',
        linkText: 'Landscapes and sea sky',
        linkHref: '/art/subject-landscapes-sea-sky/',
      },
      {
        id: '7',
        linkText: 'Portraits',
        linkHref: '/art/subject-people-portraits/',
      },
      {
        id: '8',
        linkText: 'Impressionistic art',
        linkHref: '/art/style-impressionistic/',
      },
      {
        id: '9',
        linkText: 'Nudes',
        linkHref: '/art/subject-nudes-erotic/',
      },
      {
        id: '10',
        linkText: 'Realistic',
        linkHref: '/art/style-photorealistic/product_category-painting/',
      },
      {
        id: '11',
        linkText: 'Still life',
        linkHref: '/art/subject-still-life/',
      },
      {
        id: '12',
        linkText: 'Ready to hang art',
        linkHref: '/art/framed_or_ready_to_hang-true/',
      },
      {
        id: '13',
        linkText: 'Animals and birds',
        linkHref: '/art/subject-animals-birds/',
      },
      {
        id: '14',
        linkText: 'Drawings',
        linkHref: '/art/product_category-drawing/',
      },
    ],
  },
};

export const topUspContent: SmallUspProps[] = [
  {
    icon: 'palette',
    background: colors.yellow25,
    children: (
      <>
        Shop authentic, <br />
        handmade art
      </>
    ),
  },
  {
    icon: 'profile',
    background: colors.red25,
    children: (
      <>
        Connect directly <br />
        with artists
      </>
    ),
  },
  {
    icon: 'shield',
    background: colors.skyBlue25,
    children: (
      <>
        Free global <br />
        returns
      </>
    ),
  },
  {
    icon: 'image',
    background: colors.green25,
    children: (
      <>
        Commission <br />
        an artist
      </>
    ),
  },
];

export const shopByPriceContent = (currency: CurrencySymbol) => ({
  title: 'Shop by price',
  links: [
    {
      id: '1',
      linkText: `${currency}100 and under`,
      linkHref: '/art/price_max-100/',
    },
    {
      id: '2',
      linkText: `${currency}500 and under`,
      linkHref: '/art/price_max-500/',
    },
    {
      id: '3',
      linkText: `${currency}1000 and under`,
      linkHref: '/art/price_max-1000/',
    },
    {
      id: '4',
      linkText: `${currency}1000 and over`,
      linkHref: '/art/price_min-1000/',
    },
  ],
});

export const mostPopularSearches = (currency: CurrencySymbol) => ({
  title: 'Most popular searches',
  list: [
    {
      id: 1,
      label: 'Paintings',
      link: '/art/product_category-painting/',
    },
    {
      id: 2,
      label: '20% sale',
      link: '/art/in_sale-20/',
    },
    {
      id: 3,
      label: `${currency}100 and under`,
      link: '/art/price_max-100/',
    },
    {
      id: 4,
      label: 'Sculptures',
      link: '/art/product_category-sculpture/',
    },
    {
      id: 5,
      label: 'Abstract art',
      link: '/art/subject-abstract-conceptual/',
    },
    {
      id: 6,
      label: 'Landscapes',
      link: '/art/subject-landscapes-sea-sky/',
    },
    {
      id: 7,
      label: `${currency}500 and under`,
      link: '/art/price_max-500/',
    },
  ],
});

export const discoverArtistsContent = {
  title: 'Discover artists from around the world',
  subTitle:
    'Painters. Sculptors. Photographers. And then some. Discover the artists that make our world go round.',
};

export const editorsPicksContent = {
  title: 'Editors’ picks',
  viewAllLinkHref: '/editors-picks/',
};

export const brandContent = {
  title: 'Where Artfinder got featured',
  images: [
    {
      id: 'bbc',
      src: 'https://d2m7ibezl7l5lt.cloudfront.net/img/logos/360w/bbc.png',
      alt: 'BBC logo',
      width: 360,
      height: 104,
    },
    {
      id: 'fastCompany',
      src: 'https://d2m7ibezl7l5lt.cloudfront.net/img/logos/360w/fast_company.png',
      alt: 'FastCompany logo',
      width: 360,
      height: 52,
    },
    {
      id: 'bloomberg',
      src: 'https://d2m7ibezl7l5lt.cloudfront.net/img/logos/360w/bloomberg.png',
      alt: 'Bloomberg logo',
      width: 360,
      height: 67,
    },
    {
      id: 'sundayTimes',
      src: 'https://d2m7ibezl7l5lt.cloudfront.net/img/logos/360w/sunday_times.png',
      alt: 'The Sunday Times logo',
      width: 360,
      height: 80,
    },
    {
      id: 'cnn',
      src: 'https://d2m7ibezl7l5lt.cloudfront.net/img/logos/360w/cnn.png',
      alt: 'CNN logo',
      width: 360,
      height: 168,
    },
  ],
};

export const customerReviewsContent = {
  title: 'What our customers think',
};
