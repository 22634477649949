import colors from '@/theme/constants/colors';
import elevation from '@/theme/elevation';
import focus from '@/theme/focus';
import {sizePx} from '@/theme/helpers/size';
import typography from '@/theme/typography';
import styled from 'styled-components';

export const StyledContentCard = styled.div`
  position: relative;
  width: ${sizePx(150)};
  background: ${colors.white};
  border-radius: ${sizePx(2)};
  padding: ${sizePx(8)};
  ${elevation.Default}

  > figure {
    border-radius: ${sizePx(1)};
  }

  > a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: ${sizePx(2)};
    ${focus.brand(elevation.value('Highlight'))}
  }

  > p {
    ${typography.Art}
    margin-top: ${sizePx(8)};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${elevation.Hover}
    }
  }

  &:active {
    ${elevation.Highlight}
  }

  ${focus.none}
`;
