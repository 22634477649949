import {
  StyledContentCardSkeleton,
  StyledContentCardSkeletonImage,
  StyledContentCardSkeletonTitle,
} from './styles';

const ContentCardSkeleton = (): JSX.Element => (
  <StyledContentCardSkeleton>
    <StyledContentCardSkeletonImage />
    <StyledContentCardSkeletonTitle />
  </StyledContentCardSkeleton>
);

export default ContentCardSkeleton;
