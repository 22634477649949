import {StyledColorBlock} from '@/pages/styles';
import colors from '@/theme/constants/colors';
import {
  StyledButton,
  StyledContainer,
  StyledContent,
  StyledDescription,
  StyledHeader,
  StyledImg,
} from './styles';
import constantsFactory from '@/utils/constants';
import {Props} from './typings';

const {DATA_TEST_ID} = constantsFactory();

const HomePageBannerSkeleton = ({sectionTitle}: Props) => (
  <StyledColorBlock
    $background={colors.grey25}
    $verticalPadding="xs"
    data-testid={DATA_TEST_ID.HOME_PAGE_BANNER_SKELETON}
  >
    {!!sectionTitle && <h2 className="sr-only">{sectionTitle}</h2>}

    <StyledContainer>
      <StyledContent>
        <StyledHeader />

        <StyledDescription>
          <div></div>

          <div></div>

          <div></div>
        </StyledDescription>

        <StyledButton />
      </StyledContent>

      <StyledImg></StyledImg>
    </StyledContainer>
  </StyledColorBlock>
);

export default HomePageBannerSkeleton;
