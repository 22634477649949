import {css} from 'styled-components';
import {sizePx} from './helpers/size';

const elevationSettings = {
  Default: {
    x: sizePx(0),
    y: sizePx(0),
    blur: sizePx(1),
    spread: sizePx(0),
    opacity: 0.16,
  },
  Hover: {
    x: sizePx(0),
    y: sizePx(0),
    blur: sizePx(8),
    spread: sizePx(2),
    opacity: 0.08,
  },
  Highlight: {
    x: sizePx(0),
    y: sizePx(0),
    blur: sizePx(4),
    spread: sizePx(3),
    opacity: 0.14,
  },
};

type ElevationTypes = 'Default' | 'Hover' | 'Highlight';

const value = (type: ElevationTypes) =>
  `${elevationSettings[type].x} ${elevationSettings[type].y} ${elevationSettings[type].blur} ${elevationSettings[type].spread} rgba(0, 0, 0, ${elevationSettings[type].opacity})`;

const shadow = (type: ElevationTypes) => css`
  box-shadow: ${value(type)};
`;

const Default = shadow('Default');

const Hover = shadow('Hover');

const Highlight = shadow('Highlight');

export default {
  Default,
  Hover,
  Highlight,
  value,
};
