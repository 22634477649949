import Icon from '@/atoms/Icon/Icon';
import {StyledRatingContainer} from './styles';
import colors from '@/theme/constants/colors';
import {Props} from './typings';
import constantsFactory from '@/utils/constants';

const {DATA_TEST_ID} = constantsFactory();

const Rating = ({value, size, className}: Props) => {
  const fullStars = Math.floor(value);
  const halfStar = value % 1 !== 0;
  const emptyStars = 5 - Math.ceil(value);

  return (
    <StyledRatingContainer className={className} data-testid={DATA_TEST_ID.RATING}>
      {[...Array(fullStars)].map((_, index) => (
        <Icon type="star" fontSize={size} color={colors.red100} key={index} />
      ))}

      {halfStar && <Icon type="star-half" fontSize={size} color={colors.red100} />}

      {[...Array(emptyStars)].map((_, index) => (
        <Icon type="star-outline" fontSize={size} color={colors.red100} key={index} />
      ))}
    </StyledRatingContainer>
  );
};

export default Rating;
