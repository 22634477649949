import styled, {css} from 'styled-components';
import Link from 'next/link';
import {StyledProps} from './typings';
import colors from '../../../theme/constants/colors';
import pxToRem from '../../../theme/helpers/pxToRem';
import focus from '@/theme/focus';
import {sizePx} from '@/theme/helpers/size';

const StyledTextLink = styled(Link)<StyledProps>`
  color: ${colors.red100};
  text-decoration: none;
  font-size: ${(props) => (props.$fontSize ? pxToRem(props.$fontSize) : 'inherit')};
  border-radius: ${sizePx(2)};
  ${focus.brand()}

  &:hover {
    text-decoration: underline;
  }

  //disabled state
  ${(props) =>
    props.$disabled &&
    css`
      color: ${colors.grey100};
      pointer-events: none;
    `}
`;

export default StyledTextLink;
