import button from '@/theme/constants/button';
import colors from '@/theme/constants/colors';
import typography from '@/theme/typography';
import Link from 'next/link';
import styled, {css} from 'styled-components';
import {StyledProps} from './typings';
import pxToRem from '@/theme/helpers/pxToRem';
import focus from '@/theme/focus';

export const StyledOutlinedLink = styled(Link)<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  max-width: fit-content;
  padding: 0 ${button.paddingX}px;
  white-space: nowrap;
  text-decoration: none;
  ${typography.Body1}
  color: ${colors.darkBlue100};
  border: 1px solid ${colors.darkBlue50};
  background: ${colors.white};
  border-radius: 4px;
  letter-spacing: ${pxToRem(0.4)};
  ${focus.brand()}
  ${(props) =>
    props.$maxWidth &&
    css`
      max-width: ${props.$maxWidth};
    `}

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      border-color: ${colors.darkBlue100};
      text-shadow: 0 0 ${pxToRem(0.4)} ${colors.darkBlue100},
        0 0 ${pxToRem(0.4)} ${colors.darkBlue100}, 0 0 ${pxToRem(0.4)} ${colors.darkBlue100};
      color: ${colors.darkBlue100};
    }
  }

  &:active {
    border-color: ${colors.red100};
    box-shadow: inset 0 0 0 1px ${colors.red100};
    text-shadow: 0 0 ${pxToRem(0.4)} ${colors.darkBlue100},
      0 0 ${pxToRem(0.4)} ${colors.darkBlue100}, 0 0 ${pxToRem(0.4)} ${colors.darkBlue100};
    color: ${colors.darkBlue100};
  }
`;
