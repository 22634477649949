import styled, {css, keyframes} from 'styled-components';
import breakpoints from '@/theme/constants/breakpoints';
import colors from '@/theme/constants/colors';
import {sizePx} from '@/theme/helpers/size';
import elevation from '@/theme/elevation';

const skeletonBgColor = colors.grey50;

const skeletonAnimation = keyframes`
  35% {
    opacity: 0.5;
  }
  75% {
    opacity: 1;
  }
`;

const skeletonBg = css`
  background: ${skeletonBgColor};
  animation: 2.5s ${skeletonAnimation} ease-in-out infinite;
`;

export const StyledContentCardSkeleton = styled.div`
  width: ${sizePx(150)};
  background: ${colors.white};
  border-radius: ${sizePx(2)};
  padding: ${sizePx(8)};
  ${elevation.Default}
  display: flex;
  flex-direction: column;
  row-gap: ${sizePx(8)};
`;

export const StyledContentCardSkeletonImage = styled.div`
  aspect-ratio: 3.0059 / 2;
  ${skeletonBg}
`;

export const StyledContentCardSkeletonTitle = styled.div`
  ${skeletonBg}
  height: ${sizePx(10)};
  width: 60%;

  @media only screen and ${breakpoints.device.l} {
    height: ${sizePx(12.5)};
  }
`;
