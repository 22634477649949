const wait = (timeout: number) => new Promise((resolve) => setTimeout(resolve, timeout));

/**
 * @description retries a function until it succeeds or runs out of retries
 * @param {Function} fn function to retry
 * @param {number|undefined} retries number of retries ( optional, default is 1 )
 * @return {Promise<unknown>} resolves to the return value of the function
 */
export const retryIfFails = async <D = unknown>(fn: () => Promise<D>, retries = 1): Promise<D> => {
  try {
    return await fn();
  } catch (error) {
    if (retries <= 0) {
      throw error;
    }
    await wait(200);
    return await retryIfFails(fn, retries - 1);
  }
};
