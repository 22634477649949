import useAuth from '@/hooks/use-auth/use-auth';
import ActionButton from '../Action/ActionButton';
import {emit} from '@/utils/events';
import {get, urlencoded} from 'src/services/artfinder-service';
import _ from '@/utils/_';
import {useEffect, useState} from 'react';
import {followArtistEvent} from '@/utils/gtm';
import {Props} from './typings';

const FollowButton = ({id, slug, name}: Props) => {
  const auth = useAuth();
  const [following, setFollowing] = useState(false);
  const [loading, setLoading] = useState(false);

  const getAndSetFollowing = () => {
    get('/interest/follows.js')
      .then(_.pipe(_.includes(slug), setFollowing))
      .catch((err) => {
        console.log('follows api error', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const toggleFollow = () => {
    if (!auth.isAuthenticated()) {
      emit('CLICK_ON_JOIN');
      return;
    }
    setLoading(true);
    urlencoded('/interest/', {
      object_type: 'artists.Artist',
      object_id: id,
      interested: !following,
    })
      .then(() => {
        getAndSetFollowing();
        followArtistEvent({slug: slug});
      })
      .catch((err) => {
        console.log('artist follow error', err);
        setLoading(false);
      });
  };

  useEffect(getAndSetFollowing, []);

  return (
    <ActionButton
      active={following}
      disabled={loading}
      onClick={toggleFollow}
      icon="heart"
      size="small"
      title={following ? `Unfollow ${name}` : `Follow ${name}`}
    />
  );
};

export default FollowButton;
