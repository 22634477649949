import constantsFactory from '@/utils/constants';
import axios from 'axios';
import {LOAD_BANNER_REQUEST, LOAD_BANNER_SUCCESS, LOAD_BANNER_FAILURE} from '@/redux/actionTypes';
import {retryIfFails} from '@/utils/helpers/api';

const {API} = constantsFactory();

export const getBanner = (
  country: string | undefined = undefined,
  onSuccessCallbacks: ((data?: any) => void)[] = [],
  onErrorCallbacks: ((error: any) => void)[] = [],
  cleanupCallbacks: (() => void)[] = [],
) => ({
  types: [LOAD_BANNER_REQUEST, LOAD_BANNER_SUCCESS, LOAD_BANNER_FAILURE],
  callAPI: () =>
    retryIfFails(() =>
      axios.get(`${process.env.HOST}${API.HOME_PAGE_BANNER}`, {
        params: {country: country},
        headers: {Accept: 'application/json'},
        withCredentials: true,
      }),
    ),
  onSuccess: onSuccessCallbacks,
  onCleanup: cleanupCallbacks,
  onError: onErrorCallbacks,
});
