import axios from 'axios';

import type {ReduxAsyncAction} from '../typings';

import constantsFactory from '@/utils/constants';
import paramsHelper from '@/utils/paramsHelper';
import {
  LOAD_EDITORS_PICKS_REQUEST,
  LOAD_EDITORS_PICKS_FAILURE,
  LOAD_EDITORS_PICKS_SUCCESS,
} from '@/redux/actionTypes';

const {API} = constantsFactory();

interface ImagesProps {
  index: number;
  path: string;
  width: number;
  height: number;
  type: string | null;
}

export interface EditorsPicksListItem {
  slug: string;
  name: string;
  images: ImagesProps[];
}

export interface SuccessApiResponseBody {
  count: number;
  next: string | null;
  previous: string | null;
  results: EditorsPicksListItem[];
}

interface EditorsPicksParams {
  limit: number;
}

export const getEditorsPicksList: ReduxAsyncAction<EditorsPicksParams, SuccessApiResponseBody> = (
  {limit},
  onSuccess = [],
  onError = [],
  onCleanup = [],
) => ({
  types: [LOAD_EDITORS_PICKS_REQUEST, LOAD_EDITORS_PICKS_SUCCESS, LOAD_EDITORS_PICKS_FAILURE],
  callAPI() {
    const query = paramsHelper({limit}).objectToQueryString();

    return axios.get<SuccessApiResponseBody>(`${process.env.HOST}${API.EDITORS_PICK_API}${query}`, {
      headers: {Accept: 'application/json', 'Content-Type': 'application/json'},
    });
  },
  onSuccess,
  onError,
  onCleanup,
});
