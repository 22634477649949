import {SmallCardProps} from './typings';
import {StyledSmallCard} from './styles';
import Image from '@/atoms/Image/Image';
import RibbonLabel from '@/atoms/RibbonLabel/RibbonLabel';
import Link from 'next/link';

const SmallCard = ({title, img, href, isNew = false}: SmallCardProps) => {
  return (
    <StyledSmallCard>
      {isNew && <RibbonLabel>New</RibbonLabel>}

      <Image path={img} alt={title} width={164} height={164} retina responsive />

      <p>{title}</p>

      <Link href={href} aria-label={`Go to ${title}`} />
    </StyledSmallCard>
  );
};

export default SmallCard;
