import colors from '@/theme/constants/colors';
import elevation from '@/theme/elevation';
import {sizePx} from '@/theme/helpers/size';
import typography from '@/theme/typography';
import focus from '@/theme/focus';
import styled from 'styled-components';

export const StyledSmallCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: ${sizePx(2)};
  background: ${colors.white};
  width: ${sizePx(90)};
  ${elevation.Default}

  figure {
    border-radius: ${sizePx(2)} ${sizePx(2)} 0 0;
  }

  p {
    ${typography.Body1}
    margin: ${sizePx(4)} ${sizePx(8)};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${colors.darkBlue50};
  }

  > a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: ${sizePx(2)};
    ${focus.brand(elevation.value('Highlight'))}
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${elevation.Hover}
    }
  }

  &:active {
    ${elevation.Highlight}
  }

  ${focus.none}
`;
