import styled from 'styled-components';
import StyledCtaButton from '@/atoms/Buttons/CTA/styles';
import colors from '@/theme/constants/colors';

const StyledCtaLink = styled(StyledCtaButton).attrs({as: 'a'})`
  display: inline-flex;

  &:hover {
    color: ${colors.white}; // TODO: remove once bootstrap is gone
  }
`;

export default StyledCtaLink;
