import {
  StyledContentCardImageSkeleton,
  StyledContentCardSkeleton,
  StyledContentCardTitleSkeleton,
} from './styles';

const SmallCardSkeleton = (): JSX.Element => (
  <StyledContentCardSkeleton>
    <StyledContentCardImageSkeleton />
    <StyledContentCardTitleSkeleton>
      <div />
    </StyledContentCardTitleSkeleton>
  </StyledContentCardSkeleton>
);

export default SmallCardSkeleton;
