import styled, {css, keyframes} from 'styled-components';

import colors from '@/theme/constants/colors';
import {sizePx} from '@/theme/helpers/size';

const skeletonBgColor = colors.grey50;

const skeletonAnimation = keyframes`
  35% {
    opacity: 0.5;
  }
  75% {
    opacity: 1;
  }
`;

const skeletonBg = css`
  background: ${skeletonBgColor};
  animation: 2.5s ${skeletonAnimation} ease-in-out infinite;
`;

export const StyledArtistCardSkeleton = styled.div`
  position: relative;
  background: ${colors.white};
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  transition: box-shadow 0.05s ease-in;
  border-radius: 4px;
  width: ${sizePx(150)};
  padding: ${sizePx(8)};
`;

export const StyledStyledImageGridSkeleton = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'img1 img1' 'img2 img3';
  gap: ${sizePx(1)};
  border-radius: ${sizePx(2)};
  overflow: hidden;
  margin-bottom: ${sizePx(8)};

  & > div {
    width: 100%;
    height: 100%;
    ${skeletonBg}
  }

  & > div:nth-child(1) {
    grid-area: img1;
    padding-bottom: 50%;
  }

  & > div:nth-child(2) {
    grid-area: img2;
    padding-bottom: 100%;
  }

  & > div:nth-child(3) {
    grid-area: img3;
    padding-bottom: 100%;
  }
`;

export const StyledArtistInfoSkeleton = styled.div`
  display: grid;
  grid-template-columns: ${sizePx(32)} 1fr;
  column-gap: ${sizePx(6)};
`;

export const StyledAvatarSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sizePx(4)};
  & > div {
    width: ${sizePx(32)};
    height: ${sizePx(32)};
    border-radius: 50%;
    ${skeletonBg}
  }
`;

export const StyledSummarySkeleton = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  gap: 7px;

  & > div:not(:last-child) {
    width: 100%;
    height: ${sizePx(8)};
    ${skeletonBg}
  }
`;

export const StyledFollowButtonSkeleton = styled.div`
  width: ${sizePx(16)};
  height: ${sizePx(16)};
  border-radius: 50%;
  ${skeletonBg}
`;

export const StyledRatingSkeleton = styled.div`
  width: ${sizePx(45)};
  height: ${sizePx(9)};
  ${skeletonBg}
`;
