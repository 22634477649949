import styled from 'styled-components';

import breakpoints from '@/theme/constants/breakpoints';
import typography from '@/theme/typography';
import {sizePx} from '@/theme/helpers/size';
import scrollbar from '@/theme/elements/scrollbar';

export const StyledRecentlyViewedHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: ${sizePx(4)};

  h2 {
    ${typography.Section}
  }
`;

export const StyledRecentlyViewedList = styled.ul`
  width: 100%;
  box-sizing: content-box;
  gap: ${sizePx(8)};
  display: flex;
  align-self: flex-start;
  overflow-x: scroll;
  margin-left: -${sizePx(8)};
  margin-bottom: -${sizePx(8)};
  padding: ${sizePx(8)};
  list-style: none;
  ${scrollbar.hide}

  li > div {
    flex-shrink: 0;
  }

  @media only screen and ${breakpoints.device.m} {
    gap: ${sizePx(12)};
    margin-top: ${sizePx(2)};
  }

  @media only screen and ${breakpoints.device.l} {
    align-self: unset;
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 180px));
    padding: 0;
    margin-top: ${sizePx(12)};
    margin-bottom: unset;
    margin-left: 0;
    overflow: visible;

    li > div {
      width: initial;
      min-width: initial;
    }
  }
`;
