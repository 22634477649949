import styled, {css} from 'styled-components';
import {StyledProps, StyledContainerProps} from './typings';
import colors from '../../theme/constants/colors';
import pxToRem from '../../theme/helpers/pxToRem';

export const StyledContainer = styled.span<StyledContainerProps>`
  position: relative;
  width: ${(props) => (props.$fluidWidth ? props.$width + '%' : props.$width + 'px')};

  span[class*=' icon-'],
  span[class^='icon-'] {
    position: absolute;
    right: 0;
    padding: 13px;
  }
`;

export const StyledTextfield = styled.input<StyledProps>`
  ${(props) => css`
    width: 100%;
    height: auto;
    padding: 12px 34px 12px 20px;
    font-size: ${pxToRem(14)};
    color: ${colors.darkBlue100};
    border: 1px solid ${colors.grey100};
    border-radius: 3px;
    resize: none;
    position: relative;
    line-height: normal;
    background: #ffffff;

    &:disabled {
      border-color: ${colors.grey50};
      color: ${colors.grey50};
      pointer-events: none;
      background: rgba(255, 255, 255, 0.3);
    }

    &:disabled::placeholder {
      color: ${colors.grey50};
    }

    &:focus {
      outline-width: 0;
      border-color: ${colors.darkBlue100};
    }

    &::placeholder {
      color: ${colors.grey100};
    }

    //error state
    ${props.$error &&
    css`
      border-color: ${colors.red100};

      &:focus {
        border-color: ${colors.red100};
      }
    `}
  `}
`;
