import styled from 'styled-components';

import breakpoints from '@/theme/constants/breakpoints';
import colors from '@/theme/constants/colors';
import typography from '@/theme/typography';
import {sizePx} from '@/theme/helpers/size';
import focus from '@/theme/focus';
import elevation from '@/theme/elevation';

export const StyledCustomerSupportUsp = styled.li`
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 4;
  justify-items: center;
  gap: ${sizePx(4)};
  background-color: ${colors.white};
  border-radius: ${sizePx(8)};
  padding: ${sizePx(8)};
  color: ${colors.darkBlue100};
  max-width: ${sizePx(250)};
  position: relative;
  ${elevation.Default}

  > p {
    ${typography.Body2}
    text-align: center;
    max-width: ${sizePx(200)};
    min-height: ${sizePx(22)};

    &:last-of-type {
      ${typography.Subtitle2}
      color: ${colors.red100};
      min-height: unset;
    }
  }

  > h3 {
    ${typography.Highlights}
    min-height: ${sizePx(10)};
    text-align: center;
  }

  > a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: ${sizePx(8)};
    ${focus.brand(elevation.value('Highlight'))}
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${elevation.Hover}

      & p:last-of-type {
        text-decoration: underline;
      }
    }
  }

  &:active {
    ${elevation.Highlight}
  }

  &:focus-within p:last-of-type {
    text-decoration: underline;
  }

  @media only screen and ${breakpoints.device.l} {
    padding: ${sizePx(12)};
  }
`;

export const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${sizePx(16)};
  height: ${sizePx(16)};

  & > span[role='img'] {
    color: ${colors.red100};
    font-size: 24px;
  }

  @media only screen and ${breakpoints.device.l} {
    width: ${sizePx(24)};
    height: ${sizePx(24)};

    & > span[role='img'] {
      font-size: 32px;
    }
  }
`;

export const StyledCardsList = styled.ul`
  display: grid;
  justify-content: center;
  gap: ${sizePx(12)};
  padding: 0;
  margin: 0 auto;

  @media only screen and ${breakpoints.device.m} {
    grid-template-columns: repeat(3, minmax(197px, 1fr));
  }
`;
