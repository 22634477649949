import styled from 'styled-components';
import breakpoints from '@/theme/constants/breakpoints';
import {sizePx} from '@/theme/helpers/size';

export const StyledTrustPilotContainer = styled.div`
  width: 100%;
  margin-left: ${sizePx(-5)};

  @media only screen and ${breakpoints.device.s} {
    margin-left: 0;
  }
`;
